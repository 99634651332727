/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.daangn.com/blog/archive/%EB%8B%B9%EA%B7%BC-%EC%84%A0%EA%B1%B0-%EC%84%9C%EB%B9%84%EC%8A%A4-tf-%EC%9A%B0%EB%A6%AC-%EB%8F%99%EB%84%A4-%ED%88%AC%ED%91%9C%EC%9C%A8/"
  }, "우리 동네 선거 서비스, 당근 최고 DAU를 기록하다")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
